// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bedankt-js": () => import("./../../../src/pages/bedankt.js" /* webpackChunkName: "component---src-pages-bedankt-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-franchise-js": () => import("./../../../src/pages/franchise.js" /* webpackChunkName: "component---src-pages-franchise-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-over-up-js": () => import("./../../../src/pages/over-up.js" /* webpackChunkName: "component---src-pages-over-up-js" */),
  "component---src-pages-registreer-membercard-js": () => import("./../../../src/pages/registreer-membercard.js" /* webpackChunkName: "component---src-pages-registreer-membercard-js" */),
  "component---src-pages-uitschrijven-js": () => import("./../../../src/pages/uitschrijven.js" /* webpackChunkName: "component---src-pages-uitschrijven-js" */),
  "component---src-pages-up-js": () => import("./../../../src/pages/up.js" /* webpackChunkName: "component---src-pages-up-js" */),
  "component---src-pages-vestigingen-alkmaar-js": () => import("./../../../src/pages/vestigingen/alkmaar.js" /* webpackChunkName: "component---src-pages-vestigingen-alkmaar-js" */),
  "component---src-pages-vestigingen-beverwijk-js": () => import("./../../../src/pages/vestigingen/beverwijk.js" /* webpackChunkName: "component---src-pages-vestigingen-beverwijk-js" */),
  "component---src-pages-vestigingen-castricum-js": () => import("./../../../src/pages/vestigingen/castricum.js" /* webpackChunkName: "component---src-pages-vestigingen-castricum-js" */),
  "component---src-pages-vestigingen-js": () => import("./../../../src/pages/vestigingen.js" /* webpackChunkName: "component---src-pages-vestigingen-js" */),
  "component---src-pages-welkom-js": () => import("./../../../src/pages/welkom.js" /* webpackChunkName: "component---src-pages-welkom-js" */)
}

