import React, { useState } from "react"
import _ from 'lodash'

const defaultState = {
    show: true,
    toggle: () => {},
}

const DisclaimerContext = React.createContext(defaultState)

const DisclaimerProvider = ({children}) => {
    const val = localStorage.getItem("opening-20240806")

    const [show, setShow] = useState(false)//_.isNull(val) ? true : JSON.parse(val))

    const toggler = () => {
        let _show = !show
        localStorage.setItem("siesta-20240627", JSON.stringify(_show))
        setShow(_show)
    }

    return (
        <DisclaimerContext.Provider
            value={{
                show,
                toggle: toggler,
            }}
        >
            {children}
        </DisclaimerContext.Provider>
    )
}

export default DisclaimerContext

export { DisclaimerProvider }
